<template>
  <div class="privacy-policy animate__animated animate__fadeIn">
    <div class="container">
      <div class="box">
        <div class="header">
          <h1 class="title is-4 is-marginless">Kolibri Privacy Policy</h1>
        </div>
        <div class="columns is-marginless">
          <div class="column">
            <div class="docs-content content">


              <p class="c4"><span>Last Edited on </span><b class="c6 c15">27 January, 2021</b></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c1">This privacy policy (&ldquo;Privacy Policy&rdquo;) has been compiled to better serve those who are concerned with how their Personally Identifiable Information (&ldquo;PII&rdquo;) is being used online. PII is information that can be used on its own or with other information to identify a natural person. Please read our Privacy Policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your PII when using or accessing our website, applications, or services.</span></p>
              <h3 class="c7" id="h.aiielziu54qj"><span class="c2 c0">What PII do we collect?</span></h3>
              <p class="c4"><span class="c1">The PII we may collect includes: IP Addresses.</span></p>
              <h3 class="c7" id="h.dxihnpiu0bqx"><span class="c2 c0">When do we collect PII?</span></h3>
              <p class="c4"><span class="c1">We may collect information from you when you use or access our website, applications, or services.</span></p>
              <h3 class="c7" id="h.hkol3kdse26f"><span class="c0 c2">How do we use your PII?</span></h3>
              <p class="c4"><span class="c1">We may use the information we collect from you when you access or use our website, applications, or services, or use certain other site features in the following ways:</span></p>
              <ul class="c8 lst-kix_pmf53cai3lm0-0 start">
                <li class="c3 li-bullet-0"><span class="c1">To improve our website in order to better serve you.</span></li>
                <li class="c3 li-bullet-0"><span class="c1">To protect our applications and services from attacks and other security-related events.</span></li>
              </ul>
              <h3 class="c7" id="h.thn7hojeclto"><span class="c2 c0">Who will your PII be shared with?</span></h3>
              <p class="c4"><span class="c1">We do not sell, trade, or otherwise transfer your PII to third parties other than the following:</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <ul class="c8 lst-kix_vx3fgwcedhwf-0 start">
                <li class="c3 li-bullet-0"><span class="c6">Purchasers of Our Business.</span><span class="c1">&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Law Enforcement</span><span class="c1">. We may share your information in order to comply, as necessary, with applicable laws and regulatory requirements, as well as legal process, respond to mandatory legal or governmental requests or demands for information, enforce our agreements, policies, procedures and terms of use, and protect ourselves, our users, or the general public from illegal activities.</span></li>
              </ul>
              <h3 class="c7" id="h.yxi9hix208zo"><span class="c2 c0">How long do we keep your PII?</span></h3>
              <p class="c7" id="h.4fdkpktkaout"><span class="c1">If we come into possession of your PII, we may keep your PII for up to seven days, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). </span></p>
              <p class="c7" id="h.mo1nlcgcqere"><span class="c1">When we have no ongoing legitimate business need to process PII, we will either delete or anonymize any PII in our possession, or, if this is not possible (for example, because the PII has been stored in backup archives or is stored by a third party service provider to which we only have access rights), then we will securely store any PII in our possession and isolate it from any further processing until deletion is possible.</span></p>
              <p class="c7" id="h.qjvdj3yrggww"><span class="c2 c0">How do we protect your PII?</span></p>
              <p class="c4"><span class="c1">We use industry standard best practice vulnerability scanning technology to keep our systems secure, and patch all vulnerabilities within a reasonable timeframe.</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c1">Your PII is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. Despite our efforts to protect the security of your information, no security system is always effective and we cannot guarantee that our systems will be completely secure. </span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <h3 class="c7" id="h.dnkahfza4b1m"><span class="c2 c0">Third-party links</span></h3>
              <p class="c4"><span class="c1">We do not include or offer third-party products or services on our website. The Kolibri protocol may provide you with the ability to connect with third parties such as bakers or other service providers.</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c17">On this website, we use the Cloudflare CDN (Content Distribution Network) service from Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA. Cloudflare offers better global performance through the internal CDN and additional security against DDoS attacks.For more information about Cloudflare CDN and Cloudflare&rsquo;s Privacy Policy, please visit: https://www.cloudflare.com/privacyshield/. </span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <h3 class="c7" id="h.h2qxu7adkp77"><span class="c2 c0">How does our site handle Do Not Track signals?</span></h3>
              <p class="c4"><span class="c1">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</span></p>
              <h3 class="c7" id="h.gjz6dn6nr31d"><span class="c2 c0">Does our site allow third-party behavioral tracking?</span></h3>
              <p class="c4"><span class="c1">It&#39;s also important to note that we do not allow third-party behavioral tracking</span></p>
              <h3 class="c7" id="h.2g40ezs7qn5z"><span class="c2 c0">COPPA (Children Online Privacy Protection Act)</span></h3>
              <p class="c4"><span class="c1">Persons under the age of 18 are not allowed to use or access the website, applications, or services, and we do not knowingly collect PII from persons under 18 years of age. By using or accessing the website, application, or services, you represent that you are at least 18. If we learn that we have collected PII about a person under the age of 18 years of age, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any PII we have collected from a person under age 18, please contact us at legal@hover.engineering.</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c2 c12">NOTICE TO CALIFORNIA RESIDENTS &ndash; YOUR CALIFORNIA PRIVACY RIGHTS (AS PROVIDED BY CALIFORNIA CIVIL CODE SECTION 1798.83)</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span>A CALIFORNIA RESIDENT WHO HAS PROVIDED PII TO A BUSINESS WITH WHOM HE/SHE HAS ESTABLISHED A BUSINESS RELATIONSHIP FOR PERSONAL, FAMILY, OR HOUSEHOLD PURPOSES (A &ldquo;CALIFORNIA CUSTOMER&#39;&#39;) MAY REQUEST INFORMATION ABOUT WHETHER THE BUSINESS HAS DISCLOSED PII TO ANY THIRD PARTIES FOR THE THIRD PARTIES&rsquo; DIRECT MARKETING PURPOSES. IN GENERAL, IF THE BUSINESS HAS MADE SUCH A DISCLOSURE OF PII, UPON RECEIPT OF A REQUEST BY A CALIFORNIA CUSTOMER, THE BUSINESS IS REQUIRED TO PROVIDE A LIST OF ALL THIRD PARTIES TO WHOM PII WAS DISCLOSED IN THE PRECEDING CALENDAR YEAR, AS WELL AS A LIST OF THE CATEGORIES OF PII THAT WERE DISCLOSED. CALIFORNIA CUSTOMERS MAY REQUEST FURTHER INFORMATION ABOUT OUR COMPLIANCE WITH THIS LAW BY E-MAILING LEGAL</span><span>@HOVER.ENGINEERING</span><span class="c1">. PLEASE NOTE THAT WE ARE REQUIRED TO RESPOND TO ONE REQUEST PER CALIFORNIA CUSTOMER EACH YEAR AND WE ARE NOT REQUIRED TO RESPOND TO REQUESTS MADE BY MEANS OTHER THAN THROUGH THIS E-MAIL ADDRESS.</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4 c5"><span class="c2 c0"></span></p>
              <p class="c4"><span class="c0">Privacy Rights Under the General Data Protection Regulation </span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c1">Residents of the European Economic Area (&ldquo;EEA&rdquo;) and Switzerland (&ldquo;EEA Residents&rdquo;) are entitled to make requests regarding the processing and storage of their PII. Specifically, if you are an EEA Resident, you may submit a request to us to take the following actions in relation to your PII that we hold:</span></p>
              <p class="c4 c5 c18"><span class="c1"></span></p>
              <ul class="c8 lst-kix_xqydrqclkn7m-0 start">
                <li class="c3 li-bullet-0"><span class="c6">Opt-out.</span><span class="c1">&nbsp;You may request that we stop sending you direct marketing communications which you have previously consented to receive. We may continue to send you service-related and other non-marketing communications.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Access</span><span class="c1">. You may request we provide you with information about our processing of your PII and give you access to your PII.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Rectify.</span><span class="c1">&nbsp;You may request we update or correct inaccuracies in your PII.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Erase</span><span class="c1">. You may request we erase your PII.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Export.</span><span class="c1">&nbsp;You may request we transfer a machine-readable copy of your PII to you or a third party of your choice.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Restrict.</span><span class="c1">&nbsp;You may request we restrict the processing of your PII.</span></li>
                <li class="c3 li-bullet-0"><span class="c6">Object</span><span class="c1">. You may object to our reliance on our legitimate interests as the basis of our processing of your PII that impacts your rights.</span></li>
              </ul>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span>You may do this at any time by emailing </span><span>legal@hover.engineering</span><span>. Note that we may refuse to grant your requests in whole or in part as permitted by applicable law. You have the right to complain to a data protection authority about our collection and use of your PII. For more information, please contact your local data protection authority. To find contact details, click </span><span class="c9"><a class="c11" href="https://www.google.com/url?q=http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm&amp;sa=D&amp;ust=1611859456692000&amp;usg=AOvVaw3Gh1F-hDdkbORkAKNf_7dd">here</a></span><span class="c1">.</span></p>
              <h3 class="c7" id="h.qeoaiwcrd4g6"><span class="c2 c0">Legal Basis for Processing PII</span></h3>
              <p class="c4"><span class="c1">Our legal basis for collecting and using any PII in our possession will depend on the PII concerned and the specific context in which we collect it. We may need to collect PII from you or a third party service provider only (i) where we need the PII to perform a contract with you; (ii) where the processing is in our legitimate interests and not overridden by your rights; or (iii) where we have your consent to do so. We have a legitimate interest in operating our website, applications and services, for example when improving and debugging our applications and services.</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c1">In some cases, we may also have a legal obligation to collect PII from you or may otherwise need the PII to protect your vital interests or those of another person. If we ask you to provide PII to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your PII is mandatory or not (as well as of the possible consequences if you do not provide your PII).</span></p>
              <h3 class="c7" id="h.almyu1diho3w"><span class="c2 c0">International Transfers of PII </span></h3>
              <p class="c4"><span class="c1">Your PII will be stored and processed in the United States. If you use the Services from outside the United States, you acknowledge we will transfer your PII to, and store your PII in, the United States, which may have different data protection rules than in your country, and PII may become accessible as permitted by law in the United States, including to law enforcement and/or national security authorities in the United States. </span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span class="c2 c12">Changes to our Privacy Policy</span></p>
              <p class="c4 c5"><span class="c1"></span></p>
              <p class="c4"><span>Hover Labs reserves the right to make changes to this Privacy Policy. If we make material changes to our Privacy Policy, our revised Privacy Policy will be posted at </span><span class="c9"><a class="c11" :href="window.location.href">{{ window.location.href }}</a></span><span class="c1">&nbsp;and it will be noted on our website that material changes have been made. The date of the most recent update to our Privacy Policy will be set forth in the header to the Privacy Policy.</span></p>
              <h3 class="c7" id="h.3jco0crda46v"><span class="c2 c0">Contacting Us</span></h3>
              <p class="c4"><span class="c1">If there are any questions regarding this privacy policy, you may contact us using the information below.</span></p>
              <p class="c4"><span class="c1">Hover Labs LLC</span></p>
              <p class="c4"><span class="c1">legal@hover.engineering</span></p>
              <p class="c4"><span class="c1">Last Edited on 27 January, 2021</span></p>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy",
    data() {
      return {
        window
      }
    },
    methods: {},
  }
</script>

<style lang="scss" type="text/scss">
  @import '../assets/sass/globals';
  .privacy-policy{
    background: $light-grey;
    min-height: calc(100vh - 4rem - 5px);
    .container{
      padding: 3rem 0;
    }
    .is-height-full{
      height: 100%;
    }
    .box{
      padding: 0;
      @include until($tablet){
        .sidebar{
          display: none;
          &.is-active{
            display: block;
          }
        }
      }
      .header{
        padding: 1rem 2rem;
        border-bottom: 2px solid $light-grey;
        @include until($tablet) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: .5rem 1rem;
          .title{
            margin-bottom: 0;
          }
        }
      }
      .sidebar{
        padding: 1rem;
        border-right: 2px solid $light-grey;
      }
      .docs-content{
        padding: 1rem;
        a{
          font-weight: bold;
        }
        .table{
          width: auto;
        }
      }
    }
  }
</style>
